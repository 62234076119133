import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import collectionsData from '../data/collections.json';
import productsData from '../data/products.json';

function CollectionPage() {
  const [collections] = useState(collectionsData);
  const [products] = useState(productsData);
  const [itemsPerRow, setItemsPerRow] = useState(4); // Default to 4 items per row
  const { collectionName } = useParams();

  const collection = collections.find(c => c.name === collectionName);
  const productsInCollection = products.filter(p => p.collectionId === collection?.id);


  return (
    <div>
      <h1>{collection ? collection.description : "Collection not found"}</h1>

      <button onClick={() => setItemsPerRow(prev => prev === 4 ? 2 : 4)}>
        Toggle Items per Rowz
      </button>

      <div className={`product-grid items-${itemsPerRow}`}>
        {productsInCollection.map(product => (
          <Link to={`/${collectionName}/${product.name}`} key={product.id}>
            <ProductCard 
              image={`/assets/product/images/${product.name}.svg`}
              isolated={product.isolated} 
              pastelColor={product.pastel_color}  // Pass the pastel color to the ProductCard
            />
          </Link>
        ))}
      </div>
    </div>
  );
}



export default CollectionPage;
