import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CollectionPage from './pages/CollectionPage';
import ProductPage from './pages/ProductPage';
import HomePage from './components/HomePage';

import Header from './components/Header';

function App() {
  return (
    
    <div>
    
      <Router>
      <div><Header /></div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* Route for CollectionDisplay */}
          <Route path="/collections" element={<CollectionPage />} />

          {/* Existing Routes */}
          <Route path="/:collectionName" element={<CollectionPage />} />
          <Route path="/:collectionName/:productName" element={<ProductPage />} />
          {/* ... other routes ... */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
