import React from 'react';
import Card from './Card'; // Import the Card component
import './CategoryCards.css';

const CategoryCards = () => {
  return (
    <div className="container">

      {/* Second Row */}
      <Card text="Red Bandana Skull" backgroundSize="isolated" backgroundImage="assets/product/images/red-bandana-skull-isolated-0007.svg" link="/red-bandana-skull" bgColor="#ffcccc" />
      <Card text="Cute Monkey" backgroundSize="isolated" backgroundImage="/assets/product/images/cute-evil-monkey-isolated-0002.svg" link="/cute-monkey" bgColor="#c2c2f0" />
      <Card text="Christmas Tree" backgroundSize="isolated" backgroundImage="/assets/product/images/christmas-tree-isolated-0001.svg" link="/christmas-trees" bgColor="#e6ffe6" />
      <Card text="Sneaker Shoe" backgroundSize="isolated" backgroundImage="/assets/product/images/sneaker-shoe-isolated-0001.svg" link="/sneakers" bgColor="#e0e0e0" />

      <Card text="Mermaid" backgroundSize="isolated" backgroundImage="/assets/product/images/mermaid-isolated-0001.svg" link="/mermaids" bgColor="#ccf2ff" />
      <Card text="Sugar Candy Skull" backgroundSize="isolated" backgroundImage="/assets/product/images/sugar-candy-skull-isolated-0003.svg" link="/sugar-candy-skull" bgColor="#ffccff" />
      <Card text="Geometric Skull" backgroundSize="isolated" backgroundImage="/assets/product/images/geometric-skull-isolated-0002.svg" link="/skulls" bgColor="#f2f2f2" />
      <Card text="Mushroom" backgroundSize="isolated" backgroundImage="/assets/product/images/mushroom-isolated-0001.svg" link="/mushrooms" bgColor="#ccffcc" />
      <Card text="Fire Skull" backgroundSize="isolated" backgroundImage="/assets/product/images/fire-skull-isolated-0001.svg" link="/fire-skull" bgColor="#ffcc99" />
      <Card text="Cactus" backgroundSize="isolated" backgroundImage="/assets/product/images/cactus-isolated-0001.svg" link="/cactus" bgColor="#ffffcc" />
      <Card text="Cute Evil Monkey" backgroundSize="isolated" backgroundImage="/assets/product/images/cute-evil-monkey-isolated-0001.svg" link="/cute-evil-monkey" bgColor="#ffb3b3" />
      <Card text="Mountain" textColor="white" backgroundSize="full" backgroundImage="/assets/product/images/mountain-fullbg-0001.svg" link="/mountain" bgColor="#ccccff" />
      <Card text="Vampire" backgroundSize="isolated" backgroundImage="/assets/product/images/vampire-isolated-0001.svg" link="/vampire" bgColor="#ffb3b3" />

      {/* Third Row */}
    </div>
  );
}

export default CategoryCards;
