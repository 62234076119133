import React from 'react';
import CategoryCards from './CategoryCards';

function HomePage() {
  return (
    <div>
      {/* <h1>Welcome to the Homepage!</h1> */}
      <CategoryCards></CategoryCards>
      {/* <CollectionDisplay /> */}
    </div>
  );
}

export default HomePage;
