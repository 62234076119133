import React, { useState } from 'react';
import './DropDownButton.css'; // Ensure this CSS file is created and imported

const DownloadImageButtons = ({ imageName, svgMarkup }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const resolutions = {
    '2k': 2048,
    '3k': 3072,
    '4k': 4096
  };

  const downloadSVG = () => {
    const svgBlob = new Blob([svgMarkup], { type: "image/svg+xml;charset=utf-8" });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = `${imageName}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(svgUrl);
  };

  const handleDownloadPNG = (selectedResolution = null) => {
    if (dropdownOpen && selectedResolution) {
      // Initiate download
      downloadAsPNG(selectedResolution);
      setDropdownOpen(false);
    } else {
      // Toggle dropdown
      setDropdownOpen(!dropdownOpen);
    }
  };

  const downloadAsPNG = (selectedResolution) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = resolutions[selectedResolution];
      canvas.height = (img.height / img.width) * canvas.width;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(blob => {
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `${imageName}_${selectedResolution}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      }, "image/png");
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgMarkup)}`;
  };

  return (
    <div>
      <button class="download-image" onClick={downloadSVG}>Download as SVG</button>
      <div className="dropdown">
        <button onClick={() => handleDownloadPNG()} className="dropdown-button">
          Download as PNG
        </button>
        {dropdownOpen && (
          <div className="dropdown-content">
            <button onClick={() => handleDownloadPNG('2k')} className="dropdown-item">2K Resolution</button>
            <button onClick={() => handleDownloadPNG('3k')} className="dropdown-item">3K Resolution</button>
            <button onClick={() => handleDownloadPNG('4k')} className="dropdown-item">4K Resolution</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadImageButtons;
