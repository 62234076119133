import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import './Header.css'; // Make sure to create this CSS file

function Header() {
  return (
    <div className="header">
      <Link to="/">
        <img 
          src="/kraviyo_logo.png" // Replace with the path to your logo image
          alt="Logo"
          className="header-logo"
        />
      </Link>
    </div>
  );
}

export default Header;
