import React from 'react';

const Card = ({ text, backgroundImage, link, bgColor, backgroundSize, textColor }) => {
    const getBackgroundSize = (size) => {
        switch(size) {
            case 'isolated':
                return '50% 50%';
            case 'full':
                return '100% 100%';
            default:
                return 'cover'; // Default fallback
        }
    };

    const cardStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center', // Ensure the image is centered
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      backgroundSize: getBackgroundSize(backgroundSize), // Adjust background size based on the prop
      backgroundColor: bgColor
    };

    const textStyle = {
      color: textColor // Set the text color based on the prop
    };
  
    return (
      <a href={link} className="card" style={cardStyle}>
        <div className='inner-text' style={textStyle}>
            {text}
        </div>
      </a>
    );
};

export default Card;
