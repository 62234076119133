import React from 'react';
import './ColorSwatches.css'; // Assuming you have a corresponding CSS file

const ColorSwatches = ({ colors }) => {
  return (
    <div className="color-swatches-container">
      {colors.map((color, index) => (
        <div 
          key={index}
          className="color-swatch"
          style={{ backgroundColor: color }}
        ></div>
      ))}
    </div>
  );
};

export default ColorSwatches;
