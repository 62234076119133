import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ColorSwatches from '../components/ColorSwatches';
import DownloadImageButtons from '../components/DownloadImageButtons';
import productsData from '../data/products.json';
import '../pages/ProductPage.css'; // Ensure this CSS file is created and imported


// test 
function ProductPage() {
  const [product, setProduct] = useState(null);
  const [svgMarkup, setSvgMarkup] = useState('');
  const { productName } = useParams();

  useEffect(() => {
    const foundProduct = productsData.find(p => p.name === productName);
    setProduct(foundProduct);

    // Fetch the SVG file
    const fetchSvg = async () => {
      try {
        const response = await fetch(`/assets/product/images/${productName}.svg`);
        const svgText = await response.text();
        setSvgMarkup(svgText);
      } catch (error) {
        console.error("Error fetching SVG:", error);
        // Handle error, set some default state, or show an error message
      }
    };

    if (foundProduct) {
      fetchSvg();
    }
  }, [productName]);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className='graphic'>
 
    <div className='grid-item'>
        <div dangerouslySetInnerHTML={{ __html: svgMarkup }}></div>
    </div>

    <div className='grid-item-dos'>
      <h1>{product.product_title} Graphic</h1>
      <div className='attribute-tags'>{product.isolated ? "Isolated" : "Full BG"}</div>
      <ColorSwatches colors={product.colors} />
      <DownloadImageButtons imageName={product.name} svgMarkup={svgMarkup} />
    </div>
    
      

    </div>
  );
}

export default ProductPage;
