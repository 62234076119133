import React from 'react';
import './ProductCard.css';

const ProductCard = ({ image, isolated, pastelColor }) => {
  const imageClass = isolated ? 'isolated' : 'fullbg';
  const circleBackground = pastelColor || 'white';  // Use pastelColor, default to white if not available

  return (
    <div className="product-item">
      <div className="circle" style={{ backgroundColor: circleBackground }}>
        <img src={image} alt="Product Item" className={imageClass} />
      </div>
    </div>
  );
};

export default ProductCard;
